<app-nav-portfolio></app-nav-portfolio>
<div class="container services-page-container" >
  <div class="container h2 section-title">
    Backend Services
  </div>
  <div class="row"><div class="col-sm-12">I have designed and implemented solutions on both AWS and Azure</div></div>
  <div class="row" id='websites'>

    <div class="container">
      <div class="row">
        <div class="col-sm-4">
          <div class="card site-card">
            <h4>FHIR Service - Provider Search</h4>
            <a target="_blank" href="https://ghcscw.com/clinic-provider-search#/provider-search/provider-search-results">
              <img src="/assets/ghcscw-logo.png" alt="GHC of south central Wisconsin example FHIR server implementation">
            </a>
            <div class="card-body">
              <p class="card-text">Architecture and implementation of a public facing FHIR provider search in Azure.  Integrates with in-house database and exposes as FHIR REST API</p>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card site-card">
            <h4>Realtime Order Service - HL7, FHIR, AWS</h4>
            <a target="_blank" href="https://www.numotion.com/customer-center/mynumotion-app">
              <img src="/assets/mynumotion-logo.png" height="60%" width="60%" alt="My numotion logo">
            </a>
            <div class="card-body">
              <p class="card-text">AWS Serverless implementation bridging EHR HL7 orders and updates to Patients and in-house order API endpoints </p>
            </div>
          </div>
        </div>        
        </div>
  


  <div class="container h2 section-title">
    Websites
  </div>
  <div class="row" id='websites'>

    <div class="container">
      <div class="row">
        <div class="col-sm-4">
          <div class="card site-card">
            <h4>contractors.impactsolutions.co</h4>
            <img src="/assets/impactEnroll.png" class="card-img-top" alt="Impact Solutions Enrollment sample">
            <div class="card-body">
              <p class="card-text">Custom enrollment form with scrolling banner pulling from client testimonials.  Admin panel to approve and order testimonials.</p>
            </div>
          </div>
        </div>


          <div class="col-sm-4">
            <div class="card site-card">
              <h4><a href="https://mattchandler.us">mattchandler.us</a></h4>
              <div class="card-body">
                <img src="/assets/mattchandlerus.png" class="card-img-top" alt="...">
                <p class="card-text">The site you currently using is written in Angular 9 with ng-bootstrap, material, and font-awesome.</p>
                <a target="_blank" href='https://github.com/goability/websites'>[source-code]</a>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="card site-card">
              <h4><a target="_blank " href="http://fleenorbros.com">fleenorbros.com</a></h4>
              <img src="/assets/fleenor.png" class="card-img-top" alt="Fleenorbros.com example">
              <div class="card-body">
                <p class="card-text">Updates to existing trucking site written in ES6 and CSS</p>
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        <div class="col-sm-4">&nbsp;</div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <div class="card site-card">
            <h4><a target="_blank " href="http://beautynwa.com">beautynwa.com</a></h4>
            <img src="/assets/beautynwa.png" class="card-img-top" alt="...">
            <div class="card-body">
              <p class="card-text">A wordpress site developed for a cosmetogy school in Northwest Arkansas</p>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card site-card">
            <h4>warehousedashboard.com</h4>
            <img src="/assets/warehousedashboardlogin.png" class="card-img-top" alt="...">
            <div class="card-body">
              <p class="card-text">Warehouse Management Software for inventory movement.  Provides client, admin, and employee logins.
                <br>PHP/JS/JQuery/Bootstrap/MySQL hosted with AWS EC2
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card site-card">
            <h4><a target="_blank " href="http://mitchelldrug.com">michelldrug.com</a></h4>
            <img src="/assets/mitchelldrug.png" class="card-img-top" alt="...">
            <div class="card-body">
              <p class="card-text">Pharmacy Site serving three locations.  Written in Wordpress with template customizations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container h2 section-title">
    Projects
  </div>
  <div class="container">
    <div class="row" id='projects'>
        <div class="container">
          <div class="row">
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="media">
                  <div class="media-body h4">Warehouse Dashboard</div>
                  <div class="media-right" style="font-size:3em; padding-bottom:2px; color: SkyBlue;">
                    <i class="fas fa-warehouse"></i>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                  <div class="card" width="100%">inventory.warehousedashboard.com/<Br><Br> A membership site with RESTful backend service and modern front-end modeled after a public warehouse, showcasing configuration based development.
                    <br>PHP/[Mysql PDO]/HTML/Angular JS
                    <div><i>ACTIVE</i>&nbsp; <a target="_blank" href="https://github.com/goability/warehousedashboard">github</a></div>
                  </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="media">
                  <div class="media-body h4">Replay</div>
                  <div class="media-right" style="font-size:3em; padding-bottom:2px; color: RoyalBlue;">
                    <i class="fas fa-camera"></i><i class="fas fa-reply"></i>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                  <div class="card">This project promotes image replay of a set of connected Wi-Fi cameras, allowing replay of specific time events.<br>[Javascript, Node.js, GoLang, C#, OpenCV, and Linux infrastructure)
                    <div><i>2018</i>&nbsp;<a href="https://github.com/goability/ReplayAdminTools">[github]</a></div>
                  </div>
              </div>
            </div>
          </div>
          <div class="row">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="media">
                    <div class="media-body h4">Thinking Of You</div>
                    <div class="media-right" style="font-size:3em; padding-bottom:2px; color: DarkSalmon;">
                      <i class="fas fa-portrait"></i>
                    </div>
                  </div>
                </div>
                <div class="panel-body">
                    <div class="card">The objective of this project was to promote remote actuation using a touch enabled picture frame.  Through the simple touch of a button, a user could send a quick message to a remote user to let them know that they were thinking of them.  A canned message would show up on the remote picture  frame.<br>[BLE (Bluetooth Low Energy), Node.js, MQTT, and an Intel Edison].  This was a innovation project sponsored by Intel.
                      <div><i>2016</i></div>
                      <div class="media-right">
                        <img src='../assets/thinkingOfYouDesignClip.png' style="border-radius:20px;"/>
                      </div>
                    </div>

                </div>
              </div>
          </div>
          <div class="row">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="media">
                    <div class="media-body h4">BLE Nightlight</div>
                    <div class="media-right" style="font-size:3em; padding-bottom:2px; color: RoyalBlue;">
                      <i class="fab fa-bluetooth-b"></i>
                    </div>
                  </div>
                </div>
                <div class="panel-body">
                  <div class="card">
                    Using the GATT and GAP technologies of the Bluetooth Low Energy specification with Node.js, a simple ON/OFF nightlight provides a smartphone enabled nightlight that can be discovered and controlled using near-field BLE or HTTP.
                    <div><i>2016</i></div>
                  </div>

                </div>
              </div>
          </div>
        </div>
    </div>
  </div>
</div>
