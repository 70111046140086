import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { ContactMessage } from '../contact-message';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-form-contact',
  templateUrl: './formcontact.component.html',
  styleUrls: ['./formcontact.component.less']
})
export class FormcontactComponent implements OnInit {


  constructor( private http: HttpClient) { }

  ngOnInit() { }

  dataModel = new ContactMessage('', '', '', '');
  submitted = false;
  successMessage = '';
  messageHeaders =  {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  //sendMessageURL: string = 'http://mattchandler.us/_internalServices/sendMessage.php';

  onSubmit() {
    this.submitted = true;
    //alert(this.sendMessageURL);
    /*
    // v1 - Uncomment this to send data to the php script
    this.http.post(this.sendMessageURL, this.dataModel, this.messageHeaders).subscribe(
        (val) => {
            console.log("POST RETURNED SUCCESS",
                        val);
            this.successMessage = 'Thank you.  Message received';

        },
        error => {

            console.log("POST RETURNED ERROR: ", error.message);
            this.successMessage = 'Error encountered: ' + error.message;
        },
        () => {
            console.log("POST COMPLETED");
            window.setTimeout(function(){
              window.location.replace("http://mattchandler.us");
            },2000);

        });*/
        /*
          v2 = send to firestore DB

        */

        var contactMessage =
          {
            datetime: Date.now(),
            email: this.dataModel.fromEmailAddess,
            name: this.dataModel.fromName,
            message: this.dataModel.messageBody,
            subject: 'Message from mattchandler.us'
          };

          this.createContactMessageEntry(contactMessage);

  }
  createContactMessageEntry(data) {
    return new Promise<any>((resolve, reject) =>{
       
    });
  }

  get diagnostic() { return JSON.stringify(this.dataModel); }


}
