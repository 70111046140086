
<nav class="navbar" [ngClass]="{ 'hidden': isHidden }">
  <ul class="pagination">
    <li><a mat-list-item href="/about" class="btn btn-sm btn-outline-secondary mr-2">about</a> </li>
    <li><a mat-list-item href="/portfolio" class="btn btn-sm btn-outline-secondary mr-2">portfolio</a> </li>
    <li><a mat-list-item href="/services" class="btn btn-sm btn-outline-secondary mr-2">services</a> </li>
    <li><a mat-list-item href="/resume" class="btn btn-sm btn-outline-secondary mr-2">resume</a></li>
    <li><a mat-list-item href="/contact" class="btn btn-sm btn-outline-secondary mr-2">contact</a></li>  
  </ul>
</nav>
