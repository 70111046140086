<div class="container content-container">
  <div class="row" >
    <div class="col-md-auto" align="left" style="display: inline-block;">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <img src='../assets/mattchandlerprofile.png' style="border-radius:10px;">
              <h4><b>Matt Chandler</b></h4>Fayetteville, Arkansas
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <a href="https://twitter.com/mattchandlerus" target="_blank" class="fab fa-twitter"></a>&nbsp;
              <a href="https://www.linkedin.com/in/mattchandlerus" target="_blank" class="fab fa-linkedin"></a>&nbsp;
              <a href="https://www.github.com/goability" target="_blank" class="fab fa-github"></a>
          </div>
          </div>
        </div>
    </div>
    <div class="col-sm">
      <div>
        <div class="panel-body">
          <ul class="list-group">
            <li class="list-group-item">
              <a href="http://mattchandler.us" target="_blank">
                <span class="glyphicon glyphicon-th"></span> mattchandler.us
              </a>
            </li>
            <li class="list-group-item">
              <a href="mailto:matt.chandlerAZ@gmail.com" target="_blank">
                <span class="glyphicon glyphicon-envelope"></span> matt.chandleraz@gmail.com
              </a>
            </li>
            <li class="list-group-item">
              <a href="../assets/resume_MattChandler.pdf" target="_blank">
                <span class="glyphicon glyphicon-download"></span> Download Resume
              </a>
            </li>
   
          </ul>
        </div>

      </div>
    </div>
  </div>