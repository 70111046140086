<div class="container" style="white-space: nowrap; vertical-align: top; padding-top:70px;">
  <div class="row" >
    <div class="col-xs-4" align="left" style="display: inline-block;">
      <img style="border-radius:12px;" src="../assets/mattchandlerprofile.jpg" height="150px">

        <h4>Matt Chandler<Br>Software Architect and Engineer</h4>

    </div>
    <div class="col-xs-8" align="left" style="display: inline-block;">
      <ul class="list-group">
        <li class="list-group-item">
          <a href="http://mattchandler.us" target="_blank">
            <span class="glyphicon glyphicon-th"></span> mattchandler.us
          </a>
        </li>
        <li class="list-group-item">
          <a href="mailto:matt.chandlerAZ@gmail.com" target="_blank">
            <span class="glyphicon glyphicon-envelope"></span> matt.chandleraz@gmail.com
          </a>
        </li>
        <li class="list-group-item">
          <a href="../assets/resume_MattChandler.pdf" target="_blank">
            <span class="glyphicon glyphicon-download"></span> Download Resume
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="container" style="white-space: nowrap;">
<div class="row">
  <div class="col-xs-4" class="skills">
    <h2 align="text-center">Skills</h2>
    <p class="text-sm-left">
      I have had exposure to many different technologies over the years.<a routerLink="/services" class="btn btn-sm btn-outline-secondary mr-2">See experience levels</a><br>
    <br>
    <strong>Leadership</strong>
    Technical Team Lead and Project Management<br>
    Intel Die Prep Factory Technical Lead<br>
    FedEx - Offshore Technical Lead for PScan project (11+ dev team)<br>
    Hyland Architect (5 devs, PM alignment)

    <strong>Languages</strong><br>
    PHP, Javascript, Typescript, Python, Node.js, .NET, C, C++, C#, HTML, CSS, Java, bash, powershell<br><br>
    <strong>Database</strong><Br>
    MySQL, mongoDB, Cosmos, Dynamo, Oracle, Postgres<br><br>
    <strong>Source Control</strong><Br>
    Git, TFS, Nant, Nunit<br><br>
    <strong>Operating Systems</strong><br>
    Linux, Windows, MacOS<br>
    <strong>Virtualization</strong><br>
    Docker, Kubernetes, VMWare, VirtualBox<br><br>

    <strong>Hardware and IOT</strong><br>
    Arduino, Intel MCUs, Bluetooth LE, MQTT, ESP8266/Node MCU
  </p>
  <p class="text-sm-left">
    <strong>Other</strong><br>
    Electronics Hobbyist and Maker<Br>
    Technical Writer<br>Agile Processes<br>
    OO Design Performance/Scale Analysis<br><br>
  </p>
  <p class="text-sm-left">
      <strong>Publications</strong><br>
      <a href="https://software.intel.com/en-us/articles/intel-enhanced-privacy-id-epid-security-technology" target="_blank" >EPID – Intel’s Enhanced
      Privacy ID</a><br>
      <a href="assets/publication_intel_mkl.pdf" target="_blank" >Using Intel’s MKL with Arduino Create</a><br>
  </p>
  <p class="text-sm-left">
    <strong>Awards</strong><br>
    Intel Software Quality<br>
    TD Excellence<br>
    TD APC First<br>
    First no-code Controller
  </p>
  <p class="text-sm-left">
    <strong>Interests/Passions</strong><br>
    STEM and community,
    Mentoring, Family, Playing
    Music, Astronomy,
    Electronics/maker, Hiking,
    Razorback Basketball
  </p>
   </div>
<div class="col-xs-6" class="resume">
  <h2 align="text-center">Resume</h2>
  <p class="text-sm-left">
    A Senior Software Engineer with over 15 years of industry experience at Fortune 50 companies.
Proven capability for leading projects to success with an understanding of team and technical
leadership, and a broad technical background spanning numerous programming languages,
platforms, and technologies.
  </p>
  <p class="text-sm-left">
    <strong>Caylent (2023-Present)</strong><Br>
    In my current role at Caylent, I am responsible for designing cloud-native solutions for clients in AWS. I have worked on Cognito Integration in React, Designed and implemented a Pub/Sub for FloSport, Inc., and most recently have worked on a SAAS application in AWS that uses serverless and PHP.
    <Br><Br>
    <strong>Hyland</strong> (2022-2023)<br>
    In the Software Architect 3  role, I support a development team of four mid to senior software engineers working on the healthcare HL7/FHIR modules for OnBase.  Working on an Agile team, day to day tasks include code reviews, product design documentation and work breakdown, as well as development tasks as needed in C#/C++.  To support cloud migration, I led the architecture analysis of the current cloud hostable state of the product for Operational Excellence and Security controls.  In addition, I led training review sessions to help promote AWS certification across the team.
    <br><br>
    <strong>Nordic</strong> (2021-2022)<br>
    At Nordic, I provide architecture and development for projects in the healthcare interoperability space. Helping to bridge clinical health records securely to the cloud, and between private networks, I work daily on projects involving HL7 v2, FHIR, RESTful services, API design, OAuth and token based security, sql and no-sql data storage concepts, and using different languages such as C# .NET, typescript, or python -- all while toggling between Azure and AWS platforms at any given time.
    <br><br>
    <strong>Hogcloud, Inc. </strong> (2021-Present) - Arkansas S Corporation<br>
    <strong>Ability, Inc. </strong> (2019-2021) - Colorado S Corporation<br>
    As an independent contractor, I provide website development and software consulting for individuals and small businesses from my home office. What began in colorado under Ability, Inc and Ability Micro out of Frederick Colorado, has now moved to Fayetteville Arkansas under a new name Hogcloud, Inc.
    <Br>Latest focus:  AWS consulting, Full stack website development (PHP:Node/ES6:React/Angular), word-press CMS code customizations, warehouse comm service utilizing node.js, AWS services, and product containerization and orchestration using Docker+Kubernetes.
  <br><br>
    <strong>Untangle, Inc - Senior FullStack Software Engineer</strong>(2019-2020)<br>
    As a member of the cloud engineering team, I provide design and development solutions for front and back-end applications in a fast paced Agile environment.
    At any given time, a task might involve front-end work that requires skills in JavaScript/Ext-JS/CSS/MySQL.  Back-end work requires different skill sets in PHP, Java, .NET, and python for work related to the site, appliance management system, cloud-services (which execute in AWS S3), and distributed appliances in the field respectively.  Knowledge of networking, virtualization, and Debian shell scripting is also used daily.
    <br><br>

    <strong>Senior Technical Lead WiPro/FedEx</strong>(2018-2019)<br>
    Assigned as technical lead, I worked closely with FedEx architects to modernize a legacy scanning
    product, used at over 2000 stations globally. Designed RESTful APIs, coded alongside the team,
    and ensured proper scale by applying performance testing. Governed by SAFe Agile, within three
    months, the first version was released to production, followed by quarterly cadenced releases.
    Technologies: RESTful API design/scale, python wsgi, PHP Reporting service, Oracle DB design, File
    archiving (C++ service), Redhat Linux shell scripting, git, CI/CD.<br><br>

<strong>Software Enabling Engineer Intel</strong> (2016-2018)<br>
Representing Intel, consulted with external small to medium sized businesses on Intel hardware and
services in the commercial IOT vertical. Validated vendor solutions related to TPM Security, VPRO, BLE, and
backend python/node.js development. Created technical statements of work, and presented validation
reports to upper management. Regularly researched new technologies resulting in publications to Intel
Developer’s Zone. Designed and led a Hackathon for 100+ attendees using Curie and ESP8266
microcontrollers. Led STEM efforts for local elementary school resulting in establishment of inaugural
robotics club. Using Node, BLE, and MQTT, developed innovation project that promoted remote actuation
and two way personal communications.
<br><br>
<strong>Factory Area Technical Lead Intel</strong> (2012-2016)<br>
Designed and Piloted first Middleware factory automation system, migrating .NET 3.0 client controller logic
to .NET Windows Workflow middle-tier architecture, reducing client code stack by 90%, in some cases with
zero code at controller layer due to client library module reuse.
Led a team of 14 developers, chairing weekly meetings using Agile processes. Used python to manage large
collections of files, content parsing for engineering data analysis to backend systems, and factory vision
metrology analysis using openCV libraries.
<br><br>
<strong>Station Controller Technical Lead Intel</strong> (2008-2011)<br>
Designed first Advanced Process Control station controller in Intel’s Assembly factory supporting both
present technologies as well as retrofitting older .NET 1.0 code to v3.0 under fast-paced task force
environment to enable new product IvyBridge ramp. Founded and chaired weekly meetings for release
processes, roadmap, and collaboration for team of 14 developers. Development of utilities using .NET to
help team with testing setup and playback simulation management.
<br><br>
<strong>Senior Software Engineer Intel</strong> (2005-2007)<br>
Responsible for design and development of two metrology, and four next generation process controllers,
including first high volume parallel processing wafer processing tool to enable Intel Atom Silverthorne ramp.
<br><br>
<strong>Software Engineer Intel</strong>(2004-2005)<br>
Designed and developed Web based application for Tool Scheduling and Utilization using LAMP
architecture. Created full test suite, documentation, and training for both customers and development
teams to ensure product vitality and support. Application executed on Linux architecture, Apache web
server, MySQL database, PHP server scripting, and HTML/JavaScript client scripting.
  </p>
  <h4>Education</h4>
  <p class="text-sm-left">
    Computer Systems Engineering, BS ’97, University of Arkansas
  </p>
</div>
</div>
</div>
