<app-nav-portfolio></app-nav-portfolio>
<div class="container services-page-container" content-page-container>
  <div class="h2">
    Employment History
  </div>
  <div class="row" id='employmenthistory'>
    <div class="container">
      <div class="row">

        <div class="col-sm-12">
      <div class="page-header" id='employment'>
        <img src='../assets/caylent-logo.png' alt="Caylent logo" height=20% width=20% >&nbsp;&nbsp;
        <img src='../assets/hogcloud-logo1.png' alt="Hyland logo" height=20% width=20% >&nbsp;&nbsp;
          <img src='../assets/hylandlogo.png' alt="Hyland logo" height=20% width=20% >&nbsp;&nbsp;
          <img src='../assets/nordiclogo.png' alt="Nordic logo" >&nbsp;&nbsp;
          <img src='../assets/abilitylogo.png' height=75px width=75px>&nbsp;&nbsp;
          <img src='../assets/untanglelogo.png' height=10% width=10%>&nbsp;&nbsp;
          <img src='../assets/fedexlogo.png' height=75px width=75px>&nbsp;&nbsp;
          <img src='../assets/intellogo.png' height=75px width=75px>&nbsp;&nbsp;
          <img src='../assets/usoclogo.png' height=75px width=75px>&nbsp;&nbsp;
          <img src='../assets/mcilogo.png' height=75px width=75px>&nbsp;&nbsp;
      </div>
    </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="h4 employment-company-name">
                2023 --> Present :: Caylent
              </div>
              Cloud Architect, Cloud Native Applications
            </div>
            <div class="panel-body">
              <div class="card">
                In my current role at Caylent, I am responsible for designing cloud-native solutions for clients in AWS.
              </div>
            </div>
          </div>
        </div>        
        <div class="col-sm-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="h4 employment-company-name">
                2022 --> 2023 :: Hyland Software
              </div>
              Software Architect, Health Content Services
            </div>
            <div class="panel-body">
              <div class="card">
                In the Software Architect 3  role, I support a development team of four mid to senior software engineers working on the healthcare HL7/FHIR modules for OnBase.  Working on an Agile team, day to day tasks include code reviews, product design documentation and work breakdown, as well as development tasks as needed in C#/C++.  To support cloud migration, I led the architecture analysis of the current cloud hostable state of the product for Operational Excellence and Security controls.  In addition, I led training review sessions to help promote AWS certification across the team.
              </div>
            </div>
          </div>
        </div>        
        <div class="col-sm-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="h4 employment-company-name">
                2021 --> 2022 :: Nordic
              </div>
              Senior software engineer, Digital Health, Interoperability
            </div>
            <div class="panel-body">
              <div class="card">
In my current role at Nordic Consulting, I provide architecture and development for projects in the healthcare interoperability space. Helping to bridge clinical health records securely to the cloud, and between private networks, I work daily on projects involving HL7 v2, FHIR, RESTful services, API design, OAuth and token based security, sql and no-sql data storage concepts, and using different languages such as C# .NET, typescript, or python -- all while toggling between Azure and AWS platforms at any given time.              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="h4 employment-company-name">
                2019 --> Present :: Ability and Hogcloud
              </div>
              Small business owner, Providing Software Development Solutions
            </div>
            <div class="panel-body">
              <div class="card">I provide software solutions, consulting, and modern website design/development from my home office.  Hogcloud is the new company, incorporated in Arkansas that replaces Ability and Ability Micro.  <br>Clients include: Keels Consulting Services, Inc. out of Neosho, Missouri.
              </div>
            </div>
          </div>
        </div>

          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="h4 employment-company-name">2019 --> 2020 :: Untangle, Inc.</div>
                Senior FullStack Software Engineer, Cloud Engineering
              </div>
              <div class="panel-body">
                <div class="card">
                  As a member of the cloud engineering team, I provide design and development solutions for front and back-end applications in a fast paced Agile environment.  At any given time, a task might involve front-end work that requires skills in JavaScript/Ext-JS/CSS/MySQL.  Back-end work requires different skill sets in PHP, Java, .NET, and python for work related to the site, appliance management system, cloud-services, and distributed appliances in the field respectively.  Knowledge of networking, virtualization, and Debian shell scripting is also used daily.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="h4 employment-company-name">2018 --> 2019 :: FedEx Services</div>Technical Team Lead, Software Engineering contractor for WiPro Limited
              </div>
              <div class="panel-body">
                <div class="card">
                  Assigned as technical lead for an offshore development team of eleven, I worked closely with FedEx architects to modernize the current performance scan product, which services over 2000 endpoint scanning stations around the world.  Following SAFe Agile processes, our team quickly integrated into the larger program 'Train', and including daily standup meetings and larger planning events.  Within three months, the first version was released to production, followed by quarterly cadanced releases.   Technologies: RESTful service API design/scale, python wsgi, PHP  Reporting site, Oracle DB management, File archiving (C++ service), shell scripting, CI/CD.
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading"><div class="h4 employment-company-name">2004-2018 :: Intel Corporation</div>Senior Software Engineer, Technical Team Lead Factory Automation</div>
              <div class="panel-body">
                <div class="card">
                  Working at Intel for 14 years provided the opportunity to create and collaborate on many technologies related to factory automation.  Using primarily .NET technologies, I was technical and area lead of many projects that help to ensure Intel's factories are running efficient.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading"><div class="h4 employment-company-name">1999-2004 :: Ability Engineering, Inc. </div>Owner</div>
              <div class="panel-body">
                <div class="card">
                    Incorporated in both Colorado and Arizona under S Corp Ability Engineering, Inc., developed and hosted over fifteen websites, consulted businesses on ECommerce, internal IT and networking managment and provided in-home computer repair and tutoring services to local community members.  Developed local membership sites using LAMP stack with over 800 active members that promoted local businesses and free for-sale postings.  Wrote C modem software to bridge telephony communications between hard of hearing and businesses - service ran on Linux server array.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading"><div class="h4 employment-company-name">1999 :: United States Olympic Committee</div>Contractor</div>
              <div class="panel-body">
                <div class="card">
                    <strong>Webmaster</strong>  In a six month contract, helped to maintain the usoc.org website structure and maintenance.
                    Posted athelete spotlight articles, and also helped to prepare athelete photo-shot data collection for the 1999 Pan American games in Winnipeg.
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="panel panel-default">
              <div class="panel-heading"><div class="h4 employment-company-name">1997-1998 :: MCI, Inc. </div>Contractor</div>
              <div class="panel-body">
                <div class="card">
                    <strong>Software Developer</strong> Implemented controls in C++ as part of the enterprise messaging system.
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
