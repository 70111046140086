import { Component, OnInit } from '@angular/core';
import '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  CopyrightMessage = `(c)Copyright ${new Date().getFullYear()} Matt Chandler, All Rights Reserved`

}
