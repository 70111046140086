import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {HomeComponent } from './home/home.component';
import {ContactComponent } from './contact/contact.component';
import {PortfolioComponent } from './portfolio/portfolio.component';
import {ServicesComponent } from './services/services.component';
import {AboutComponent } from './about/about.component';
import {ResumeComponent } from './resume/resume.component';
import {ProjectsComponent } from './projects/projects.component';
import {PublicationsComponent } from './publications/publications.component';
import {EmploymenthistoryComponent } from './employmenthistory/employmenthistory.component';

import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';




const routes: Routes = [
  { path: 'privacy', component:PrivacypolicyComponent},
  { path: '', component: HomeComponent},
  { path: 'portfolio', component: PortfolioComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'services', component: ServicesComponent},
  { path: 'about', component: AboutComponent},
  { path: 'resume', component: ResumeComponent},
  { path: 'projects', component: ProjectsComponent},
  { path: 'publications', component: PublicationsComponent},
  { path: 'employmenthistory', component: EmploymenthistoryComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
