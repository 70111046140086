<app-nav-portfolio></app-nav-portfolio>
<div class="container services-page-container">
  <div class="h2">
    Publications
  </div>
<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default">
      <div class="panel-heading"><h4>Using Intel® Math Kernel Library with Arduino Create</h4></div>
      <div class="panel-body">
        <div class="well">
        Published November 2017, this article presents use cases and provides examples which make use of the Intel® Math Kernel Library (Intel® MKL). Arduino Create*, a cloud-based IDE and UP2*, a single board computer based on Intel’s Apollo Lake platform, are used for the examples.
        <a href="assets/publication_intel_mkl.pdf" target="_blank" class="btn btn-sm btn-secondary fa fa-paperclip"> Read article</a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-sm-12">
    <div class="panel panel-default">
      <div class="panel-heading"><h4>Intel® Enhanced Privacy ID (EPID) Security Technology</h4></div>
      <div class="panel-body">
        <div class="well">
          Published July, 2017 this article discusses the Intel® Enhanced Privacy Identification (EPID) security scheme, which helps to specifically address two device level security issues; anonymity and membership revocation.
          <a href="https://software.intel.com/en-us/articles/intel-enhanced-privacy-id-epid-security-technology" target="_blank" class="btn btn-sm btn-secondary fa fa-paperclip"> Read article</a>
      </div>
      </div>
    </div>
  </div>
</div>
</div>
