<div class="container content-container">
  <div class="row" >
    <div class="col-md-auto" align="left" style="display: inline-block;">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <img src='../assets/mattchandlerprofile.png' style="border-radius:10px;">
              <h4><b>Matt Chandler</b></h4>Fayetteville, Arkansas
            </div>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <a href="https://twitter.com/mattchandlerus" target="_blank" class="fab fa-twitter"></a>&nbsp;
              <a href="https://www.linkedin.com/in/mattchandlerus" target="_blank" class="fab fa-linkedin"></a>&nbsp;
              <a href="https://www.github.com/goability" target="_blank" class="fab fa-github"></a>
          </div>
          </div>
        </div>
    </div>
    <div class="col-sm">
      <div>
        <div class="panel-body">
          <div class="card">
            <div class="media">
              <div class="media-left">
              
                <img src="../assets/razorbackLogo.jpg" class="media-object" style="width:100px;border-radius:20px;">
                <a href="https://caylent.com/" target="_blank"><img src="../assets/caylent-logo.png" height="20%" width="20%"></a>
             
              </div>
              <div class="media-body">
                <div class=""><br>
                I currently live in Fayetteville, Arkansas and am focusing on architecture and development of cloud-based solutions in AWS full time at <a href="https://caylent.com/" target="_blank">Caylent</a>
                <br><br>
                Recent projects have focused on transfer of data between clinical electronic health record systems (Healthcare Interoperability). <br>
                <br>
                <a href="https://nordicglobal.com" target="_blank"><img src="../assets/nordiclogo.png" class="media-object" style="width:100px; border-radius:20px;"></a>
                <a href="https://hyland.com" target="_blank"><img src="../assets/hylandlogo.png" class="media-object" style="width:100px; border-radius:20px;"></a><br>
 
                I am also continuing to pursue AWS certifications<br>
                <a href="https://www.credly.com/badges/e4d63c95-f7ba-4d27-8d6b-560febdfcae5" target="_blank"><img src="../assets/aws-cert-cloud-foundations.png" class="media-object" width="20%" height="40%" ></a>
                <a href="https://www.credly.com/badges/b16a9409-fb59-426a-89aa-f9e0f13b1f43" target="_blank"><img src="../assets/aws-cert-solutions-arch-associate.png" class="media-object" width="20%" height="40%" ></a>
              
                <Br><br>
                  Nights and weekends continue to fuel my consulting company, <b>Hogcloud, Inc. </b> which is primarly focused on educational services in the maker community.
                  <img src="../assets/hogcloud-logo1.png" style="float: right;">
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="card">
            <div class="media">
              <div class="media-body">
                <h4 class="media-heading">2018 - 2021 :: Colorado</h4>
                <div class="">
                  <img src="../assets/wiprologo.jpg" class="media-object" style="width:100px; border-radius:20px;">
                  <img src="../assets/fedexlogo.png" class="media-object" style="width:100px; border-radius:20px;">
                  <img src='../assets/untanglelogo.png'  class="media-object" style="width:100px; border-radius:20px;">
                  <img src='../assets/abilitylogo.png'  class="media-object" style="width:100px; border-radius:20px;">
                  <br>
                  After 16 years in Arizona, I moved to Colorado in search of cooler weather.
                  I setup a small business, Ability, Inc., and completed contracts with FedEx as a tech lead, followed by a full stack role at a cybersecurity company, Untangle, located in Boulder.
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="card">
            <div class="media">
              <div class="media-left">
                  <img src="../assets/intellogo.png" class="media-object" style="width:100px">
              </div>
              <div class="media-body">
                <h4 class="media-heading">2004-2018 - The Intel years</h4>
                  Introduced through a client, a contract was offered at Intel, Corp which was extended into a fulltime employment offer.
                  <b>Ability Engineering, Inc.</b> was dissolved in Arizona and Colorado.  My career in factory automation at Intel over 14 years helped to define who I am today.  The opportunities I was given to solve hard problems, working with smart people around the world, and the feeling of successfully delivery was an amazing and rewarding experience.
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="card">
            <div class="media">
              <div class="media-left">
                <img src="../assets/cactusimage.png" class="media-object" style="width:100px">
              </div>
              <div class="media-body">
                <h4 class="media-heading">2002 - 2004 : From Snow to Sun</h4>
                  After five short years in Colorado Springs, I decided to move to Phoenix.  One of my clients relocated to Arizona, and extended me a partnership in their business if I relocated with them.
                  This also provided me the opportunity to expand my business into Arizona, which serviced home PC troubleshooting, tutoring, website development (85212.com), and additional custom development consulting projects.

                  <b>Ability Engineering, Inc.</b>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="card">
            <div class="media">
              <div class="media-left">
                  <img src="../assets/coloradoMountainLogo.png" class="media-object" style="width:100px">
              </div>
              <div class="media-body">
                <h4 class="media-heading">1997 - 2002 Colorado Springs</h4>
                  After college, my first jobs were in Colorado Springs.  I contracted at MCI, United States Olympic Committee, and eventually incorporated a business that provided a wide array of development and IT services.
                  <b>Ability Engineering, Inc.</b>
              </div>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="card">
            <div class="media">
              <div class="media-left">
                <img src="../assets/razorbackLogo.jpg" class="media-object" style="width:100px;border-radius:20px;">
                
              </div>
              <div class="media-body">
                <h4 class="media-heading">1992 - 1997 Razorback</h4>
                <div style="word-wrap: normal;">
                  After high school, I attended the University of Arkansas and graduated with a degree in Computer Systems Engineering.  
                  <img src="../assets/final-four-1994.png" height="50%" width="50%" style="float: right;">During my time in college, the men's basketball team won the 1994 national championship, which I was lucky enough to attend in person.  I still follow the Razorback basketball team each year and tune in to each and every game.
                  
                </div>  
                </div>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="card">

            <div class="media-body">
              <h4 class="media-heading">My Background</h4>
              <div>
              Born in Memphis, Tennessee and grew up in Northeastern Arkansas in an area called the Delta region that is rich in culture, fertile farmland, and friendly people.
              Hometown: <b>Marked Tree, Arkansas</b>
              </div>
              <div class="media-right">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51951.669702161336!2d-90.46590437524297!3d35.52950781569017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87d5b84465c73643%3A0xb221a5d8d760a0e3!2sMarked+Tree%2C+AR+72365!5e0!3m2!1sen!2sus!4v1550299536535" width="200" height="150" frameborder="0" style="border:0; align:right;" allowfullscreen></iframe>
              </div>
            </div>

          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
