<form width=100% #contactForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="row" >
    <div class="col-sm-12 form-group" class="field">
      <label for="fromName">Your name</label>
      <input type="text" required [(ngModel)]="dataModel.fromName" #name="ngModel" id="fromName" name="fromName" class="form-control" placeholder="Your name"  style="width:400px;">
      <div [hidden]="name.valid || name.pristine"
           class="alert alert-danger">
        Name is required
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 form-group" class="field">
      <label for="fromEmailAddess">Email</label>
      <input type="email" [(ngModel)]="dataModel.fromEmailAddess" name="fromEmailAddess" class="form-control" placeholder="Your Email"  style="width:400px;">
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 form-group" class="field">
      <label for="messageBody">Message</label>
      <input type="textarea" [(ngModel)]="dataModel.messageBody" name="messageBody" class="form-control" placeholder="Leave me a message."  style="width:400px;">
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <button type="submit" class="btn btn-success primary" [disabled]="!contactForm.form.valid" style="margin-top: 5px; color: White; background-color: RoyalBlue;">Send Message</button>
    </div>
  </div>
</form>
<h3>{{successMessage}}</h3>
