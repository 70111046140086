<div class="container content-page-container" style="padding-top:5px;">
  <div class="row">
    <div class="col-sm-12">
      <ul class="pagination" >
        <li>   <a routerLink="/services" class="btn btn-sm btn-outline-secondary mr-2">
              Skills
            </a></li>
        <li>    <a routerLink="/projects" class="btn btn-sm btn-outline-secondary mr-2">
            Projects
          </a></li>
        <li>      <a routerLink="/publications" class="btn btn-sm btn-outline-secondary mr-2">
              Publications
              </a></li>
        <li>      <a routerLink="/employmenthistory" class="btn btn-sm btn-outline-secondary mr-2">
              Employment
              </a></li>
      </ul>
    </div>
  </div>
</div>
