<app-nav-portfolio></app-nav-portfolio>

<div class="container services-page-container">
  <div class="h2">
    Certifications
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="panel panel-default">
        <div class="panel-body">
          <a href="https://www.credly.com/badges/d4db01bb-1e6a-4408-9266-ccc06b513693" target="_blank"><img src="../assets/aws-cert-dev-associates.png" class="media-object" width="40%" height="40%" ></a>
          <a href="https://www.credly.com/badges/b16a9409-fb59-426a-89aa-f9e0f13b1f43" target="_blank"><img src="../assets/aws-cert-solutions-arch-associate.png" class="media-object" width="30%" height="30%" ></a>
          <a href="https://www.credly.com/badges/e4d63c95-f7ba-4d27-8d6b-560febdfcae5" target="_blank"><img src="../assets/aws-cert-cloud-foundations.png" class="media-object" width="20%" height="20%" ></a>
        </div>
      </div>
    
    </div>    
  </div>
  <div class="h2">
    Services
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="panel panel-default">
        <img src='../assets/teamworkpuzzle.jpg' style='width:75px;border-radius:20px; align:"right"'>

        <div class="panel-heading h4">
                    Technical Lead
        </div>
        <div class="panel-body">

          <div class="list-group">
            <button type="button" class="list-group-item list-group-item-action">
              Experience with 10-15+ member team sizes
            </button>
            <button type="button" class="list-group-item list-group-item-action">
              Global and In-house teams
            </button>
            <button type="button" class="list-group-item list-group-item-action">
              Agile Scrum/SAFe processes
            </button>
            <button type="button" class="list-group-item list-group-item-action">
              Embrace change and schedule changes
            </button>
            <button type="button" class="list-group-item list-group-item-action">
              Excellent communications skills
            </button>
            <button type="button" class="list-group-item list-group-item-action">
              Clear reporting  to upper management and product stakeholders
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="panel panel-default">
        <img src='../assets/gear.png' style='width:75px;border-radius:20px; align:"right"'>

        <div class="panel-heading h4">Software Services</div>
        <div class="panel-body">
            <div class="list-group">
            <button type="button" class="list-group-item list-group-item-action">
              Over twenty five years experience in software service development
            </button>
            <button type="button" class="list-group-item list-group-item-action">
              Windows, Linux, and MacOS native development
            </button>
            <button type="button" class="list-group-item list-group-item-action">
              Website full-stack development, wordpress template customizations
            </button>
            <button type="button" class="list-group-item list-group-item-action">
              Amazon Web Services
            </button>
            <button type="button" class="list-group-item list-group-item-action">
              Application deployment and service orchestration using Docker+Kubernetes
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="panel panel-default">
        <img src='../assets/checklist.png' style='width:75px;border-radius:2px; align:"right"'>

        <div class="panel-heading h4">Experience</div>
        <div class="panel-body">
          Since 1997, I have worked with many different languages and technologies.  Either on the job for a corporation, a client, or a side project, I continue to learn new languages and design patterns for both front and back end, as well as mobile applications.  See below for years of experience as well as ones I am still learning <span class="badge badge-warning ">< 1</span>.

          <div class="row">
            <div class="col-sm-4">
              <div class="panel panel-default">
                <div class="panel-heading h4">Back End</div>
                <div class="panel-body">
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      API and RESTful Designs
                      <span class="badge badge-experienced ">5</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Python
                      <span class="badge badge-experienced ">5</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Node.js
                      <span class="badge badge-intermediate ">2</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      GoLang
                      <span class="badge badge-learning ">1</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Apache
                      <span class="badge badge-experienced ">7</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      PHP
                      <span class="badge badge-experienced ">7</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Shell Scripting
                      <span class="badge badge-experienced ">4</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="panel panel-default">
                <div class="panel-heading h4">Front End</div>
                <div class="panel-body">
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      HTML 5
                      <span class="badge badge-experienced ">5</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Javascript ES5/6
                      <span class="badge badge-experienced ">5</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      CSS/Less/Sass
                      <span class="badge badge-experienced ">4</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Angular
                      <span class="badge badge-intermediate ">2</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      React
                      <span class="badge badge-learning ">1</span>
                    </li>
                  </ul>
                </div>
              </div>

            </div>

            <div class="col-sm-4">
              <div class="panel panel-default">
                <div class="panel-heading h4">Mobile</div>
                <div class="panel-body">
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      React-Native
                      <span class="badge badge-learning ">&lt; 1</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Swift iOS
                      <span class="badge badge-learning ">&lt; 1</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="panel panel-default">
                <div class="panel-heading h4">Database</div>
                <div class="panel-body">
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      MySQL
                      <span class="badge badge-experienced ">6</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Oracle
                      <span class="badge badge-intermediate ">2</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Mongo
                      <span class="badge badge-intermediate ">3</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Postgres
                      <span class="badge badge-learning ">< 1</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <div class="panel panel-default">
                <div class="panel-heading h4">Release Management</div>
                <div class="panel-body">
                  <ul class="list-group">
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Project Planning and Technical Leadership
                      <span class="badge badge-experienced ">7</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Agile Scrum
                      <span class="badge badge-experienced ">7</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      SAFe Agile
                      <span class="badge badge-learning ">1</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Jenkins
                      <span class="badge badge-learning ">1</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Microsoft TFS
                      <span class="badge badge-experienced ">5</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Azure Pipelines
                      <span class="badge badge-intermediate ">2</span>
                    </li>                    
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      HP AGM
                      <span class="badge badge-learning ">1</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      IBM UDeploy
                      <span class="badge badge-learning ">1</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      git and gitlab
                      <span class="badge badge-experienced ">5</span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-8">
                <div class="panel panel-default">

                  <div class="panel-heading h4">Other</div>
                  <div class="panel-body">
                    <ul class="list-group">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        AWS S3, EC2, SNS, SES, Cloudwatch, ...
                        <span class="badge badge-intermediate ">3</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Virtualization - Docker, Kubernetes, VMWare, VirtualBox
                        <span class="badge badge-intermediate ">2</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Global teams experience, Offshore Team Lead
                        <span class="badge badge-experienced ">5</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        .NET C#
                        <span class="badge badge-experienced ">10</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        C/C++
                        <span class="badge badge-intermediate ">5</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        OO Design Concepts
                        <span class="badge badge-experienced ">10</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Team Leadership
                        <span class="badge badge-experienced ">15</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        UML Design Diagramming
                        <span class="badge badge-experienced ">15</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Technical Documentation
                        <span class="badge badge-experienced ">15</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Arduino
                        <span class="badge badge-experienced ">8</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Electronics Maker
                        <span class="badge badge-intermediate ">8</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Tutoring and Mentorship
                        <span class="badge badge-experienced ">5</span>
                      </li>

                    </ul>
                  </div>
                </div>
            </div>
          </div>

          </div>
      </div>
    </div>
  </div>
</div>
