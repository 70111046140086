<div class="container content-container">
    <div class="row" >
      <div class="panel-body">
        <title>mattchandler.us Privacy Policy</title>

Last Updated: October 2023

<h1>1. Introduction</h1>

Welcome to mattchandler.us ("the Website"). At mattchandler.us, we respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you visit our website.

<h1>2. Information We Collect</h1>

<h2>2.1.</h2>
 Personal Information: We may collect personally identifiable information, such as your name, email address, postal address, or phone number when you voluntarily submit it through forms on our website.

<h2>2.2.</h2> Non-Personal Information: We also collect non-personal information, such as browser type, device type, operating system, and browsing history for website analytics purposes.

<h1>3.</h1> 
How We Use Your Information

<h2>3.1.</h2> Personal Information: We may use your personal information to:

Respond to your inquiries and requests
Send you updates, newsletters, or marketing materials
Improve our website and services
3.2. Non-Personal Information: We use non-personal information for analytical and statistical purposes to enhance the user experience and functionality of our website.

<h1>4.</h1> 
Disclosure of Your Information

We do not sell, trade, or otherwise transfer your personal information to third parties unless we provide you with advance notice. However, we may share your information with:

Service providers who assist us in operating our website and conducting our business.
Legal authorities, as required by law.
<h1>5.</h1> 
Cookies and Tracking Technologies

We may use cookies and other tracking technologies to enhance your browsing experience and gather information about how you use our website.

<h1>6.</h1>
 Your Choices

You can choose not to provide personal information to us, but this may limit your ability to access certain features of our website. You can also manage cookie preferences through your web browser settings.

<h1>7.</h1> Security

We take data security seriously and have implemented measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction.

<h1>8.</h1> Links to Other Websites

Our website may contain links to third-party websites. We are not responsible for their content or privacy practices. We encourage you to review the privacy policies of those websites.

<h1>9.</h1> Children's Privacy

Our website is not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13 years of age.

<h1>10.</h1> Updates to this Privacy Policy

We may update this Privacy Policy periodically to reflect changes in our practices. We will post the revised policy on our website, and the updated version will be effective immediately.

<h1>11.</h1> Contact Us

If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at matt.chandleraz@gmail.com
</div>
    </div>
</div>